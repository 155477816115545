import {
  mdiBullhornOutline,
  mdiHandshake,
  mdiInformationOutline,
  mdiNewspaperVariantOutline,
} from "@mdi/js";

export default {
  state: () => ({
    main: [
      {
        icon: mdiInformationOutline,
        text: "About us",
        to: { name: "slug", params: { slug: "about" } },
      },
      {
        icon: mdiNewspaperVariantOutline,
        text: "News",
        to: {
          name: "posts",
        },
      },
      {
        icon: mdiHandshake,
        text: "Sponsors",
        to: { name: "sponsors" },
      },
      {
        icon: mdiBullhornOutline,
        text: "Media",
        to: { name: "media" },
      },
    ],
  }),

  getters: {
    main: (state) => state.main,
    canadaHelpsLink: () =>
      "https://www.canadahelps.org/en/charities/the-rotary-club-of-port-perry" +
      "-ontario-charitable-trust/p2p/port-perry-pedals-2022/",
    parkingLink: () =>
      "https://www.scugog.ca/en/live-and-play/resources/Documents/Maps-" +
      "Downtown-Port-Perry-Parking-Lots.pdf",
    arenaLink: () => "https://goo.gl/maps/edkekvf66nuZttbNA",
  },
};
